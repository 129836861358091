import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppConfig } from "src/helper/AppConfig";
import { disableDirectCommerce } from 'src/redux/slice/productSlice';
import _ from 'lodash';

/**
 * React component for Stripe card payments
 */
const CardPayment = (props) => {
  const { productData, handleClose,directCommerceData } = props;
  const { t } = useTranslation("common");
  const [stripePromise, setStripePromise] = useState();
  const dispatch = useDispatch();

  const data = productData.isUpidsProduct ? productData.upids_data  : productData.data;

  useEffect(() => {
    getStripeConfig();
  }, []);

  /**
   * Get Stripe configuration
   */
  const getStripeConfig = async (e) => {
    try {
    const { publishableKey } = await fetch(
      `${AppConfig.SERVER_URL}/integrations/stripe/config`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((r) => r.json());

    setStripePromise(loadStripe(publishableKey));
  } catch (error) {
      
  }
  };

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentName, setPaymentName] = useState("");
    const [paymentAddress, setPaymentAddress] = useState("");
    const [paymentPostalCode, setPaymentPostalCode] = useState("");
    const [paymentCity, setPaymentCity] = useState("");
    const [paymentEmail, setPaymentEmail] = useState("");
    const [paymentPhone, setPaymentPhone] = useState("");

    const [message, setMessage] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentTaxAmount, setPaymentTaxAmount] = useState();
    const [paymentShippingAmount, setPaymentShippingAmount] = useState("");
    const [paymentTotalAmount, setPaymentTotalAmount] = useState();
    const [taxText, setTaxText] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const PAYMENT_FAILED_TEXT = "Payment failed, please try again or contact support@upids.io";    

    useEffect(() => {
      calculateTaxes();
    }, []);

    // Styling for card element
    const cardElementOptions = {
      // hidePostalCode: true,
      style: {
        base: {
          backgroundColor: "#f8fcfd",
          color: "#000000",
          fontSize: "12px",
        },
        invalid: {
          color: "#fa755a",
          fontSize: "12px",
        },
      },
    };

    /**
     * Calculate taxes
     */
    const calculateTaxes = async () => {
      if (directCommerceData?.price > 0) {
        let tax = 0;

        if (directCommerceData?.tax > 0) {
          tax =
            ((parseInt(directCommerceData?.price) + parseInt(directCommerceData?.shipping)) / 100) *
            parseFloat(directCommerceData?.tax);
          tax = Math.round((tax + Number.EPSILON) * 100) / 100;
          setTaxText(t("Includes VAT") + " " + directCommerceData?.tax + "%");
        } else {
          setTaxText(t("Includes VAT") + " " + '0%');
        }

        const total =
          parseFloat(directCommerceData?.price) +
          parseFloat(directCommerceData?.shipping);

        setPaymentTaxAmount(_.toNumber(tax).toFixed(2));
        setPaymentTotalAmount(_.toNumber(total).toFixed(2));
        setPaymentShippingAmount(_.toNumber(directCommerceData?.shipping).toFixed(2));
      }
    };

    /**
     * Handler for form submit
     * Creates a payment intent and then confirms it
     */
    const handleSubmit = async () => {
      try{
        setIsLoading(true);
        setMessage(t("Processing payment."));

        if (!stripe || !elements) {
          setIsLoading(false);
          return;
        }

        let businessId;
        const businessIdObj = data.businessIdentifiers.find(
          (obj) => obj.type === "VATID"
        );

        if (businessIdObj) {
          businessId = businessIdObj.id;
        } else {
          businessId = data.businessIdentifiers[0].id;
        }

        // const description = productData.isUpidsProduct
        //   ? productData.data.productName.en
        //   : data.productName.en +
        //   " " +
        //   directCommerceData?.price +
        //   " €\n" +
        //   taxText +
        //   " " +
        //   paymentTaxAmount +
        //   " €\nShipping " +
        //   paymentShippingAmount +
        //   " €\nPayment total " +
        //   paymentTotalAmount +
        //   " €";

        const description = productData.isUpidsProduct ? productData.data.productName.en :
        `${data.productName.en} ${directCommerceData?.price} ${directCommerceData?.currencyType} \n ${taxText} ${paymentTaxAmount} ${directCommerceData?.currencyType} \n Shipping ${paymentShippingAmount} ${directCommerceData?.currencyType} \n Payment total ${paymentTotalAmount} ${directCommerceData?.currencyType}`

        // const deliveryType = data.directCommerce.deliveryType;

        // if (isUpidsProduct) {
        //   deliveryType = upidsData.directCommerce.deliveryType;
        // }

        let articleIdentifier;
        let upidsId;

        if (productData.isUpidsProduct) {
          articleIdentifier = data.articleIdentifier;
          upidsId = data.upidsId;
        } else {
          articleIdentifier = data.gtin ? data.gtin : data._2an;
        }
        // Create payment intent
        const { error: backendError, clientSecret } = await fetch(
          `${AppConfig.SERVER_URL}/integrations/stripe/create-payment-intent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              paymentMethodType: "card",
              currency: (directCommerceData?.currencyType)?.toLowerCase(),
              businessId: businessId,
              amount: paymentTotalAmount,
              price: directCommerceData?.price,
              type: "direct",
              deliveryType: directCommerceData?.deliveryType,
              description: description,
              articleIdentifier: articleIdentifier,
              upidsId: upidsId,
              productName: productData.isUpidsProduct
                ? productData.data.productName.en
                : data.productName.en,
              taxRate: directCommerceData?.tax,
              taxAmount: paymentTaxAmount,
              shippingAmount: paymentShippingAmount,
            }),
          }
        ).then((r) => r.json());

        if (backendError) {
          setIsLoading(false);
          setMessage(PAYMENT_FAILED_TEXT);
          setPaymentStatus("failed");
          return;
        }

        // Confirm payment intent
        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name: paymentName,
                email: paymentEmail,
                phone: paymentPhone,
                address: {
                  city: paymentCity,
                  line1: paymentAddress,
                  postal_code: paymentPostalCode,
                },
              },
            },
            shipping: {
              name: paymentName,
              phone: paymentPhone,
              address: {
                city: paymentCity,
                line1: paymentAddress,
                postal_code: paymentPostalCode,
              },
            },
          });

        if (stripeError) {
          setMessage(PAYMENT_FAILED_TEXT);
          setPaymentStatus("failed");
          setIsLoading(false);
          return;
        }

        if (paymentIntent.status === "succeeded") {
          setMessage(t("Payment succeeded."));
          setPaymentStatus("succeeded");
        } else {
          setMessage(PAYMENT_FAILED_TEXT);
          setPaymentStatus("failed");
        }
        setIsLoading(false);
    } catch (error) {
      setMessage(PAYMENT_FAILED_TEXT);
      setPaymentStatus("failed");
      setIsLoading(false);
      return;
    }
    };

    const handleSuccessOk = () => {
      dispatch(disableDirectCommerce());
      handleClose();
    };

    return (
      <>
        {isLoading && (
          <div className="loading-overlay">
            <TailSpin color="#ffffff" height={38} width={38} timeout={0} />
          </div>
        )}

        {paymentStatus !== "succeeded" ? (
          <form id="payment-form" onSubmit={handleSubmit}>
            <div>
              <div className="row">
                <div className="col-12">
                  <input
                    id="paymentName"
                    className="payment-input"
                    title={t("Credit card holder name")}
                    type={"text"}
                    placeholder={t("Credit card holder name")}
                    value={paymentName}
                    onChange={(e) => setPaymentName(e.target.value)}
                    required
                  />
                </div>
              </div>
              {directCommerceData?.deliveryType === "postal" && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="paymentAddress"
                        className="payment-input margin-top-10"
                        title={t("Address")}
                        type={"text"}
                        placeholder={t("Address")}
                        value={paymentAddress}
                        onChange={(e) => setPaymentAddress(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <input
                        id="paymentPostalCode"
                        className="payment-input margin-top-10"
                        title={t("Postal code")}
                        type={"text"}
                        placeholder={t("Postal code")}
                        value={paymentPostalCode}
                        onChange={(e) => setPaymentPostalCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-8">
                      <input
                        id="paymentCity"
                        className="payment-input margin-top-10"
                        title={t("City")}
                        type={"text"}
                        placeholder={t("City")}
                        value={paymentCity}
                        onChange={(e) => setPaymentCity(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="paymentPhone"
                        className="payment-input margin-top-10"
                        title={t("Phone")}
                        type={"text"}
                        placeholder={t("Phone")}
                        value={paymentPhone}
                        onChange={(e) => setPaymentPhone(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-12">
                  <input
                    id="paymentEmail"
                    className="payment-input margin-top-10"
                    title={t("Email")}
                    type={"text"}
                    placeholder={t("Email")}
                    value={paymentEmail}
                    onChange={(e) => setPaymentEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 upidsPayment__card">
                  {/* Card number */}
                  <div className="float-left">
                    <CardNumberElement
                      options={cardElementOptions}
                      className="upidsPayment__card__number"
                    />
                  </div>
                  <div className="float-left">
                    {/* Expiration date */}
                    <CardExpiryElement
                      options={cardElementOptions}
                      className="upidsPayment__card__expiry"
                    />
                  </div>
                  <div className="float-left">
                    {/* CVC */}
                    <CardCvcElement
                      options={cardElementOptions}
                      className="upidsPayment__card__cvc"
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}
              {paymentStatus === "failed" && (
                <div className="margin-top-10 red-text">{message}</div>
              )}
              {directCommerceData && (
                <div className="margin-top-10">
                  <div className="row">
                    <div className="col-6">{t("Price")}</div>
                    <div className="col-6">{_.toNumber(directCommerceData?.price).toFixed(2)} {directCommerceData?.currencyType}</div>
                  </div>
                  <div className="row">
                    <div className="col-6">{t("Shipping")}</div>
                    <div className="col-6">{paymentShippingAmount} {directCommerceData?.currencyType}</div>
                  </div>
                  <div className="row">
                    <div className="col-6">{taxText}</div>
                    <div className="col-6">{paymentTaxAmount} {directCommerceData?.currencyType}</div>
                  </div>
                  <div className="row">
                    <div className="col-6">{t("Payment total")}</div>
                    <div className="col-6">{paymentTotalAmount} {directCommerceData?.currencyType}</div>
                  </div>
                </div>
              )}
              <div className="margin-top-10">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn form-button cursor-pointer"
                  style={{ background: "#cf2948", height: 40, color: "#ffffff", fontSize: 16 }}
                >
                  {t("Process payment with Stripe")}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className="upidsPayment__success">
            <div className="upidsPayment__success__text">
              {t("Payment successfully done.")}
            </div>
            <div className="upidsPayment__success__okbtn">
              <button
                type="button"
                onClick={handleSuccessOk}
                className="btn btn-primary form-button cursor-pointer"
              >
                {t("OK")}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!stripePromise && (
        <div className="loading-overlay loading-overlay--stripePromise">
          <TailSpin color="#ffffff" height={38} width={38} timeout={0} />
        </div>
      )}

      <div className="upidsPayment">
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
        <div className="upidsPayment__footer col-12">
          <div className="upidsPayment__footer__text col-10">
            Payment is processed by
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="upidsPayment__footer__text__strip"
            >
              Stripe Inc
            </a>
            and your credit card details are not stored to UPIDS PDM.
          </div>
          <div className="upidsPayment__footer__icon col-2" ></div>
        </div>
      </div>
    </>
  );
};

export default CardPayment;
